import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import get from 'lodash.get'
import SEO from '../components/SEO'
import Layout from '../components/Layout'
import PageLayout from '../components/PageLayout'
import { Heading, Container, Box, Flex } from '../components/UI'

const Generic = ({ data: { contentfulPage: page } }) => {
  const SeoImg =
    get(page, 'image.childImageSharp.sizes.src') || get(page, 'image.fluid.src')

  return (
    <div>
      <SEO
        title={page.title}
        description={page.description}
        image={SeoImg}
        pathname={page.slug}
        article
      />
      <Layout>
        <PageLayout horizontal={page.horizontal} image={SeoImg} page={page} />
      </Layout>
    </div>
  )
}

Generic.propTypes = {}

export default Generic

export const GenericQuery = graphql`
  query GenericQuery($id: String!) {
    contentfulPage(id: { eq: $id }) {
      title
      contentful_id
      slug
      heading
      subHeading
      image {
        fluid(maxWidth: 1200) {
          ...GatsbyContentfulFluid
        }
      }
      content {
        content
        json
      }
    }
  }
`
